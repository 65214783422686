import { lazy } from "react";
import { Navigate } from "react-router-dom";
import HomeLayout from "../components/v1/Layouts/HomeLayout/HomeLayout";
import RoutesManager from "./RoutesManager";

const config = {
  layout: HomeLayout,
};

export const uris = {
  LOGIN: "/login",
  HOME: "/home",
  REGISTER: "/register",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY_EMAIL: "verify-email",
  RESEND_VERIFICATION_TOKEN: "resend-verification-token",
  VERIFY_LEVEL: "/verify-level"
};

const routes = [
  {
    path: uris.HOME,
    element: lazy(() => import("../views/Home")),
  },
  {
    path: uris.VERIFY_LEVEL,
    element: lazy(() => import("../views/VerifyLevel/VerifyLevel")),
  },
  {
    path: uris.LOGIN,
    element: lazy(() => import("../views/Auth/Login")),
  },
  {
    path: uris.REGISTER,
    element: lazy(() => import("../views/Auth/Register")),
  },
  {
    path: uris.ABOUT_US,
    element: lazy(() => import("../views/AboutUs/AboutUs")),
  },
  {
    path: uris.CONTACT_US,
    element: lazy(() => import("../views/ContactUs/ContactUs")),
  },
  {
    path: uris.PRIVACY_POLICY,
    element: lazy(() => import("../views/PrivacyPolicy/PrivacyPolicy")),
  },
  {
    path: uris.TERMS_AND_CONDITIONS,
    element: lazy(
      () => import("../views/TermsAndCondition/TermsAndConditions")
    ),
  },
  {
    path: uris.FORGOT_PASSWORD,
    element: lazy(() => import("../views/Auth/ForgotPassword")),
  },
  {
    path: uris.RESET_PASSWORD,
    element: lazy(() => import("../views/Auth/ResetPassword")),
  },
  {
    path: uris.VERIFY_EMAIL,
    element: lazy(() => import("../views/Auth/VerifyEmail")),
  },
  {
    path: uris.RESEND_VERIFICATION_TOKEN,
    element: lazy(() => import("../views/Auth/ResendVerificationToken")),
  },
  {
    path: "/",
    element: () => <Navigate to="/home" />,
  },
  {
    path: "/*",
    element: lazy(() => import("../views/NotFound404")),
  },
];

const homeRoutesManager = new RoutesManager(config, uris, routes);

export default homeRoutesManager;
