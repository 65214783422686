import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { footerLinks } from "../../Footer/Footer";

const NavbarFooter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={2} padding={1}>
      <Stack direction="row" columnGap={1} flexWrap="wrap">
        {footerLinks.map(({ translation, href }) => (
          <Typography
            key={translation}
            onClick={() => navigate(href)}
            color="textSecondary"
            fontSize="small"
            sx={{
              cursor: "pointer",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t(translation)}
          </Typography>
        ))}
      </Stack>
      <Typography color="textSecondary" fontSize="small">
        © {moment().format("YYYY")} ExamPalace.com
      </Typography>
    </Stack>
  );
};

export default NavbarFooter;
