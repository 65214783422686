import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../hooks/useIsMobile";

export const footerLinks = [
  {
    translation: "about_us",
    href: "/about-us",
  },
  {
    translation: "contact_us",
    href: "/contact-us",
  },
  {
    translation: "privacy_policy",
    href: "/privacy-policy",
  },
  {
    translation: "terms_and_conditions",
    href: "/terms-and-conditions",
  },
];
const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  return (
    <Stack
      sx={{
        backgroundColor: "background.paper",
        marginTop: "auto",
        py: 2,
        px: 1,
        marginBottom: 2,
      }}
    >
      <Stack alignItems={isMobile ? "left" : "center"} gap={2}>
        <Stack
          direction={"row"}
          gap={isMobile ? 1 : 2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {footerLinks.map(({ translation, href }) => (
            <Typography
              key={translation}
              variant="caption"
              onClick={() => navigate(href)}
              color="textSecondary"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {t(translation)}
            </Typography>
          ))}
        </Stack>
        <Typography
          color="textSecondary"
          variant="caption"
          textAlign={"center"}
          sx={{ marginBottom: 10 }}
        >
          ExamPalace.com © {moment().format("YYYY")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Footer;
