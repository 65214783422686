import { useCallback, useEffect } from "react";
import axios from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { User } from "../contexts/AuthContext";
import useAuth from "../hooks/useAuth";

type Props = {
  children: React.ReactNode;
};

const AuthGuard = ({ children }: Props) => {
  const navigate = useNavigate();
  const { saveUser, user } = useAuth();

  const handleRefresh = useCallback(async () => {
    const refreshToken = localStorage.getItem("exampalace_refresh_token");
    if (!refreshToken) {
      navigate("/login");
    } else {
      if (user.isAuth) return;
      try {
        const {
          data: { accessToken, user: fetchedUser, ranking },
        } = (await axios.post("/auth/refresh", {
          refreshToken,
        })) as {
          data: {
            accessToken: string;
            user: User;
            ranking: { examsCompleted: number; points: number };
          };
        };
        localStorage.setItem("exampalace_access_token", accessToken);
        saveUser({
          ...fetchedUser,
          isAuth: true,
          totalExamsTaken: ranking.examsCompleted,
          totalPoints: ranking.points,
        });
      } catch (error) {
        navigate("/login");
      }
    }
  }, [navigate, saveUser, user.isAuth]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return <>{children}</>;
};

export default AuthGuard;
