import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import CookieConcernDialog from "components/common/CookieConcernDialog/CookieConcernDialog";
import Footer from "../../../Footer/Footer";
import HomeNavigation from "./HomeNavigation/HomeNavigation";
import MainLogo from "./Logo/Logo";
import MobileMenu from "./MobileMenu/MobileMenu";
import "/node_modules/flag-icons/css/flag-icons.min.css";

type HomeLayoutProps = {
  children: React.ReactNode;
};

const HomeLayout = ({ children }: HomeLayoutProps) => (
  <>
    <AppBar position="fixed" sx={{ bgcolor: "background.paper" }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <MainLogo />
        <MobileMenu />
        <HomeNavigation />
      </Stack>
    </AppBar>
    {children}
    <Footer />
    <CookieConcernDialog />
  </>
);

export default HomeLayout;
