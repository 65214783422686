import React, { createContext, useCallback, useState } from "react";
import axiosConfig from "../../axiosConfig";

interface TrashedExamsProps {
  trashedExams: any[];
  totalTrashedExams: number;
  loading: boolean;
  error: string;
  numberOfPages: number;
  handlePagination: (e: any, p: number) => void;
  setTrashedExams: React.Dispatch<React.SetStateAction<any[]>>;
  fetchTrashedExams: () => Promise<void>;
}

const TrashedExamsContext = createContext<TrashedExamsProps | undefined>(
  undefined
);

interface TrashedExamsProviderProps {
  children: React.ReactNode;
}

export const TrashedExamsProvider: React.FC<TrashedExamsProviderProps> = ({
  children,
}) => {
  const [trashedExams, setTrashedExams] = useState<any[]>([]);
  const [totalTrashedExams, setTotalTrashedExams] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const numberOfPages = Math.ceil(totalTrashedExams / 6);
  const fetchTrashedExams = useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axiosConfig.get("/exam/trashed");
      setTrashedExams(response.data);
      setTotalTrashedExams(response.data.length);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [page]);

  // Handling pagination
  const handlePagination = (e: any, p: number) => {
    setPage(p);
  };

  return (
    <TrashedExamsContext.Provider
      value={{
        trashedExams,
        totalTrashedExams,
        loading,
        error,
        numberOfPages,
        handlePagination,
        setTrashedExams,
        fetchTrashedExams,
      }}
    >
      {children}
    </TrashedExamsContext.Provider>
  );
};

export const TrashedExamsConsumer = TrashedExamsContext.Consumer;
export default TrashedExamsContext;
