import { useCallback, useState } from "react";

interface IUseAsync<T> {
  loading: boolean;
  error: string | null;
  response: T | null;
  execute: (asyncFn: () => Promise<T>) => void;
}
// This hook is handling async tasks
const useAsync = <T,>(): IUseAsync<T> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<T | null>(null);

  const execute = useCallback(async (asyncFn: () => Promise<T>) => {
    setLoading(true);
    setError(null);
    setResponse(null);
    try {
      const { data } = (await asyncFn()) as any;
      setResponse(data);
      return data;
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, error, response, execute };
};

export default useAsync;
