function addPrefixToObject(obj: Record<string, string>, prefix: string): any {
  const newObj: Record<string, string> = {};

  // Iterate over each property of the object
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      // Append the prefix to the property value
      newObj[prop] = prefix + obj[prop];
    }
  }

  return newObj;
}

export default addPrefixToObject;
