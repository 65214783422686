import { useEffect, useState } from "react";

// This hook is helping to toggle the dialog
const useDialog = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  useEffect(() => {
    return () => setOpen(false);
  }, []);
  return { open, handleClose, handleOpen };
};

export default useDialog;
