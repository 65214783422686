import { Divider, Stack, Tab, Tabs } from "@mui/material";
import { USER_ROUTES } from "../../../../constants";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

type Props = {};

const tabRoutes = [
  {
    label: "",
    href: USER_ROUTES.PROFILE,
  },
  {
    label: "",
    href: USER_ROUTES.ACHIEVEMENTS,
  },
  {
    label: "",
    href: USER_ROUTES.FAVORITE_EXAMS,
  },
];

const ProfileNavigationTabs = (props: Props) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();

  const location = useLocation();

  useEffect(() => {
    // Find the index of the current location.pathname in tabRoutes
    const currentTab = tabRoutes.findIndex((route) =>
      location.pathname.includes(route.href)
    );
    setValue(currentTab);
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack sx={{ marginTop: 2}}>
      <Divider />
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="User Profile Tabs"
      >
        <Tab
          label="Profile"
          onClick={() => navigate(`/app/profile/${user._id}`)}
        />
        <Tab
          label="Achievements"
          onClick={() => navigate(`/app/achievements/${user._id}`)}
        />
        <Tab
          label="Favorite Exams"
          onClick={() => navigate(`/app/favorite-exams/${user._id}`)}
        />
      </Tabs>
    </Stack>
  );
};

export default ProfileNavigationTabs;
