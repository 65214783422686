import {
  Analytics,
  Book,
  Dashboard,
  EmojiEvents,
  Favorite,
  Grading,
  Group,
  GroupAdd,
  Inventory,
  ListAlt,
  PlaylistPlay,
  Spellcheck,
} from "@mui/icons-material";
import BadgeIcon from "components/v2/icons/BadgeIcon";
import { USER_ROUTES } from "../../../constants";

const navBarRoutes = [
  // {
  //   path: USER_ROUTES.NEWS_FEED,
  //   icon: <Newspaper />,
  //   label: "routes.home",
  // },
  // {
  //   path: USER_ROUTES.SKILL_TREE,
  //   icon: <AccountTreeRoundedIcon />,
  //   label: "routes.skills",
  // },
  {
    path: USER_ROUTES.PROFILE,
    icon: <BadgeIcon />,
    label: "routes.myProfile",
    profile: true,
  },
  {
    path: USER_ROUTES.BROWSE_EXAMS,
    icon: <Dashboard color="secondary" />,
    label: "routes.home",
  },
  {
    path: USER_ROUTES.INSIGHTS,
    icon: <Analytics color="secondary" />,
    label: "routes.insights",
    insights: true,
  },
  {
    path: USER_ROUTES.FAVORITE_EXAMS,
    icon: <Favorite color="secondary" />,
    label: "routes.favoriteExams",
  },
  {
    path: USER_ROUTES.EXAM_PLAYLIST,
    icon: <PlaylistPlay color="secondary" />,
    label: "routes.myPlaylist",
  },
  {
    path: USER_ROUTES.VOCABULARIES,
    icon: <Spellcheck color="secondary" />,
    label: "routes.vocabularies",
  },
  {
    path: USER_ROUTES.SENTENCES,
    icon: <ListAlt color="secondary" />,
    label: "routes.sentences",
  },
  {
    path: USER_ROUTES.LIST_FRIENDS,
    icon: <Group color="secondary" />,
    label: "routes.friends",
  },
  {
    path: USER_ROUTES.SEARCH_USERS,
    icon: <GroupAdd color="secondary" />,
    label: "routes.findFriends",
  },
  {
    path: USER_ROUTES.CEFR_EVALUATION,
    icon: <Grading color="secondary" />,
    label: "evaluation",
  },
  {
    path: USER_ROUTES.RANKING,
    icon: <EmojiEvents color="secondary" />,
    label: "routes.ranking",
  },
  {
    path: USER_ROUTES.BUNDLES,
    icon: <Inventory color="secondary" />,
    label: "routes.bundles",
  },
  {
    path: USER_ROUTES.BLOGS,
    icon: <Book color="secondary" />,
    label: "Blogs",
  },
];

export default navBarRoutes;
