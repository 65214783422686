import {
  AddCircle,
  AutoDelete,
  Book,
  BugReport,
  BusinessCenter,
  Discount,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NoteAddRounded,
  People,
  Report,
  SaveAs,
  School,
  Spellcheck,
  Subject,
} from "@mui/icons-material";
import AdUnits from "@mui/icons-material/AdUnits";
import Logout from "@mui/icons-material/Logout";
import { Collapse, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import DirectionToggler from "components/common/DirectionToggler/DirectionToggler";
import NavbarFooter from "components/common/Navbar/NavbarFooter";
import ThemeToggler from "components/common/ThemeToggler/ThemeToggler";
import MainLogo from "components/v1/Layouts/HomeLayout/Logo/Logo";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { initialUserState } from "../../../contexts/AuthContext";
import useAuth from "../../../hooks/useAuth";
import useIsMobile from "../../../hooks/useIsMobile";
import LanguageSelect from "../../v1/Layouts/HomeLayout/LanguageSelect/LanguageSelect";
import NavBarRouteItems, { NavBarRouteItem } from "./NavBarRouteItems";

const teacherRoutes = [
  {
    path: "/app/admin/create-exam",
    icon: <NoteAddRounded color="warning" />,
    label: "routes.createExam",
  },
  {
    path: "/app/admin/create-vocabulary",
    icon: <AddCircle color="warning" />,
    label: "routes.createVocabulary",
  },
  {
    path: "/app/admin/draft-exams",
    icon: <SaveAs color="warning" />,
    label: "routes.draftExams",
  },
  {
    path: "/app/admin/trashed-exams",
    icon: <AutoDelete color="warning" />,
    label: "routes.trashedExams",
  },
  {
    path: "/app/admin/list-vocabularies",
    icon: <Spellcheck color="warning" />,
    label: "routes.listVocabularies",
  },
];

const adminRoutes = [
  {
    path: "/app/admin/list-users",
    icon: <People color="warning" />,
    label: "routes.listUsers",
  },
  {
    path: "/app/admin/list-teachers",
    icon: <School color="warning" />,
    label: "routes.listTeachers",
  },
  {
    path: "/app/admin/list-blogs",
    icon: <Book color="warning" />,
    label: "routes.listBlogs",
  },
  {
    path: "/app/admin/list-coupons",
    icon: <Discount color="warning" />,
    label: "routes.listCoupons",
  },
  {
    path: "/app/admin/ad-slots",
    icon: <AdUnits color="warning" />,
    label: "routes.listAds",
  },
  {
    path: "/app/admin/list-bundles",
    icon: <BusinessCenter color="warning" />,
    label: "routes.listBundles",
  },
];

const MainMenuDialog = () => {
  const { user, saveUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const [collapseReport, setCollapseReport] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigate = (url: string): void => {
    setAnchorEl(null);
    setTimeout(() => {
      navigate(url);
    }, 0);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  useEffect(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = () => {
    saveUser(initialUserState);
    localStorage.removeItem("exampalace_refresh_token");
    localStorage.removeItem("exampalace_access_token");
    localStorage.removeItem("userId");
    setAnchorEl(null);
    navigate("/login");
  };

  return (
    <>
      <Tooltip title="Menu">
        <Avatar
          onClick={handleMenuClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          sx={{
            cursor: "pointer",
          }}
          src={user.avatar}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          ".MuiMenu-list": {
            padding: 0,
            width: "380px !important",
          },
        }}
      >
        <Stack>
          {isMobile && <NavBarRouteItems disableHide={true} />}
          {teacherRoutes.map((route) => {
            if (user.role === "teacher" || user.role === "admin") {
              return (
                <NavBarRouteItem
                  route={route}
                  key={route.path}
                  disableHide={true}
                />
              );
            }
            return null;
          })}
          {adminRoutes.map((route) => {
            if (user.role === "admin") {
              return (
                <NavBarRouteItem
                  route={route}
                  key={route.path}
                  disableHide={true}
                />
              );
            }
            return null;
          })}
          {user.role === "admin" && (
            <>
              <MenuItem onClick={() => setCollapseReport((prev) => !prev)}>
                <ListItemIcon>
                  <Report fontSize="small" />
                </ListItemIcon>
                Reports
                <ListItemIcon>
                  {collapseReport ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  )}
                </ListItemIcon>
              </MenuItem>
              <Collapse in={collapseReport} sx={{ marginLeft: 4 }}>
                <MenuItem
                  onClick={() => handleNavigate("/app/admin/report/bugs")}
                >
                  <ListItemIcon>
                    <BugReport fontSize="small" />
                  </ListItemIcon>
                  Bugs
                </MenuItem>
                <MenuItem
                  onClick={() => handleNavigate("/app/admin/report/exams")}
                >
                  <ListItemIcon>
                    <Subject fontSize="small" />
                  </ListItemIcon>
                  Exams
                </MenuItem>
              </Collapse>
            </>
          )}
          <MenuItem onClick={handleLogout} sx={{ padding: 2 }}>
            <ListItemIcon>
              <Logout fontSize="small" color="error" />
            </ListItemIcon>
            <Typography color="error" variant="h5" fontWeight={700}>
              Logout
            </Typography>
          </MenuItem>
          <Stack
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              marginY: 1,
            }}
          >
            <ThemeToggler />
            <DirectionToggler />
          </Stack>
          <LanguageSelect onSelect={() => setAnchorEl(null)} />
          <Stack ml={1}>
            <MainLogo />
          </Stack>
          <NavbarFooter />
        </Stack>
      </Menu>
    </>
  );
};

export default MainMenuDialog;
