import React, { createContext, useCallback, useState } from "react";
import axiosConfig from "../../axiosConfig";

interface ExamPlaylistContextProps {
  examPlaylist: any[];
  totalExamsInPlaylist: number;
  setTotalExamsInPlaylist: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  error: string;
  numberOfPages: number;
  handlePagination: (e: any, p: number) => void;
  setExamPlaylist: React.Dispatch<React.SetStateAction<any[]>>;
  fetchExamPlaylist: (userId: string) => Promise<void>;
}

const ExamPlaylistContext = createContext<ExamPlaylistContextProps | undefined>(
  undefined
);

interface ExamPlaylistProviderProps {
  children: React.ReactNode;
}

export const ExamPlaylistProvider: React.FC<ExamPlaylistProviderProps> = ({
  children,
}) => {
  const [examPlaylist, setExamPlaylist] = useState<any[]>([]);
  const [totalExamsInPlaylist, setTotalExamsInPlaylist] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const numberOfPages = Math.ceil(totalExamsInPlaylist / 6);

  const fetchExamPlaylist = useCallback(
    async (userId: string) => {
      setLoading(true);
      setError("");
      try {
        const response = await axiosConfig.get(
          `/exam-playlist?page=${page}&limit=${6}&userId=${userId}`
        );

        setExamPlaylist(response.data.examPlaylist);
        setTotalExamsInPlaylist(response.data.totalExamsInPlaylist);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [page]
  );
  // Handling pagination
  const handlePagination = (e: any, p: number) => {
    setPage(p);
  };

  return (
    <ExamPlaylistContext.Provider
      value={{
        examPlaylist,
        totalExamsInPlaylist,
        setTotalExamsInPlaylist,
        loading,
        error,
        numberOfPages,
        handlePagination,
        setExamPlaylist,
        fetchExamPlaylist,
      }}
    >
      {children}
    </ExamPlaylistContext.Provider>
  );
};

export const ExamPlaylistConsumer = ExamPlaylistContext.Consumer;
export default ExamPlaylistContext;
