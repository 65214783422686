import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "../components/AuthGuard";
import { USER_ROUTES, USER_ROUTES_PATH_PREFIX, USER_URIS } from "../constants";
import DashboardLayout from "../layouts/DashboardLayout";
import RoutesManager from "./RoutesManager";
import ProfileLayout from "layouts/ProfileLayout";

const config = {
  guard: AuthGuard, // Component to use for route authentication
  layout: DashboardLayout, // Component to use for route layout
  prefix: USER_ROUTES_PATH_PREFIX, // Prefix to add to all route paths
};

// Array of route objects for the user routes
const routes = [
  {
    path: `${USER_URIS.TAKE_EVALUATION}`,
    element: lazy(
      () => import("../views/v2/User/Evaluation/TakeEvaluation/TakeEvaluation")
    ),
  },
  {
    path: `${USER_URIS.FAVORITE_EXAMS}/:userId`,
    element: lazy(() => import("../views/v2/User/Exam/FavoriteExams")),
    layout: ProfileLayout,
  },
  {
    path: `${USER_URIS.ACHIEVEMENTS}/:userId`,
    element: lazy(
      () => import("../views/UsersPublicProfile/components/Achievements")
    ),
    layout: ProfileLayout,
  },
  {
    path: `${USER_URIS.RECENT_EXAMS}/:userId`,
    element: lazy(() => import("../views/v2/User/Exam/RecentExams")),
    layout: ProfileLayout,
  },
  {
    path: USER_URIS.SKILL_TREE,
    element: lazy(() => import("../views/v2/User/SkillTree/SkillTree")),
  },
  {
    path: USER_URIS.LIST_FRIENDS,
    element: lazy(() => import("../views/v2/User/Friends/ListFriends")),
  },
  {
    path: USER_URIS.CEFR_EVALUATION,
    element: lazy(() => import("../views/v2/User/Evaluation/Evaluation")),
  },
  {
    path: USER_URIS.SEARCH_USERS,
    element: lazy(() => import("../views/v2/User/Friends/SearchFriends")),
  },
  {
    path: `${USER_URIS.TAKE_EXAM}/:aliasId/`,
    element: lazy(() => import("../views/v2/User/Exam/TakeExam/TakeExamView")),
  },
  {
    path: `${USER_URIS.TAKE_EXAM_FROM_PLAYLIST}/:aliasId/:playingId`,
    element: lazy(() => import("../views/v2/User/Exam/TakeExam/TakeExamView")),
  },
  {
    path: `${USER_URIS.SEARCH}/:query`,
    element: lazy(
      () => import("../views/v2/User/Exam/SearchExams/SearchExams")
    ),
  },
  {
    path: USER_URIS.NEWS_FEED,
    element: lazy(() => import("../views/v2/User/NewsFeed/NewsFeed")),
  },
  {
    path: USER_URIS.VOCABULARIES,
    element: lazy(
      () => import("../views/v2/User/Vocabularies/ListVocabularies")
    ),
  },
  {
    path: `${USER_URIS.CREATE_SENTENCE}/:vocabularyId`,
    element: lazy(() => import("../views/v2/User/Vocabularies/CreateSentence")),
  },
  {
    path: USER_URIS.SENTENCES,
    element: lazy(() => import("../views/v2/User/Vocabularies/ListSentences")),
  },
  {
    path: USER_URIS.BROWSE_EXAMS,
    element: lazy(
      () => import("../views/v2/User/Exam/BrowseExams/BrowseExams")
    ),
  },
  {
    path: USER_URIS.EXAM_PLAYLIST,
    element: lazy(
      () => import("../views/v2/User/Exam/ExamPlaylist/ExamPlaylist")
    ),
  },
  {
    path: USER_URIS.RANKING,
    element: lazy(() => import("../views/Ranking/MainRanking")),
  },
  {
    path: USER_URIS.HISTORY,
    element: lazy(() => import("../views/ExamHistory/ExamHistory")),
  },
  {
    path: USER_URIS.BUNDLES,
    element: lazy(() => import("../views/Bundles/AllBundlesForUsers")),
  },
  {
    path: `${USER_URIS.BUNDLE}/:bundleId`,
    element: lazy(() => import("../views/Bundles/SingleBundleForUsers")),
  },
  {
    path: USER_URIS.BLOGS,
    element: lazy(() => import("../views/Blogs/BlogCatalog")),
  },
  {
    path: USER_URIS.FAVORITE_BLOGS,
    element: lazy(() => import("../views/Blogs/FavoriteBlogs")),
  },
  {
    path: `${USER_URIS.BLOGS_BY_CATEGORY}/:category`,
    element: lazy(() => import("../views/Blogs/BlogsByCategory")),
  },
  {
    path: `${USER_URIS.BLOG}/:slug`,
    element: lazy(() => import("../views/Blogs/ReadBlog")),
  },
  {
    path: USER_URIS.PRODUCTS,
    element: lazy(() => import("../views/Products/Products")),
  },
  {
    path: USER_URIS.PROFILE,
    element: lazy(() => import("../views/UsersProfile/UsersProfile")),
  },
  {
    path: `${USER_URIS.PROFILE}/:userId`,
    element: lazy(
      () => import("../views/UsersPublicProfile/UsersPublicProfile")
    ),
    layout: ProfileLayout,
  },
  {
    path: `${USER_URIS.INSIGHTS}/:userId`,
    element: lazy(() => import("../views/UserInsights/UserInsights")),
  },
  {
    path: USER_URIS.CHECKOUT,
    element: lazy(() => import("../views/Products/CheckoutForm2")),
  },
  {
    path: `${USER_URIS.PROMO_CODE}/:code`,
    element: lazy(() => import("../views/Products/CheckoutByPomoLink")),
  },
  {
    path: USER_URIS.NOTIFICATIONS,
    element: lazy(() => import("../views/UserNotifications/UserNotifications")),
  },
  {
    path: USER_URIS.PLANS,
    element: lazy(() => import("../views/Products/PlanSelectView")),
  },
  {
    path: USER_URIS.CART,
    element: lazy(() => import("../views/Cart/CartView")),
  },
  {
    path: "/",
    element: () => <Navigate to={USER_ROUTES.BROWSE_EXAMS} />,
  },
  {
    path: "/*",
    element: () => <Navigate to={USER_ROUTES.BROWSE_EXAMS} />,
  },
];

const userRoutesManager = new RoutesManager(config, USER_URIS, routes);

export default userRoutesManager;
