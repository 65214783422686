import { Theme, useMediaQuery } from "@mui/material";

// This is a media query for mobile device
const useIsMobile = () => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  return { isMobile };
};

export default useIsMobile;
