import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { IconButton, Tooltip } from "@mui/material";
import { THEMES } from "../../../constants";
import useSettings from "../../../hooks/useSettings";
const ThemeToggler = () => {
  const { settings, saveSettings } = useSettings();

  const toggleTheme = () => {
    const newTheme =
      settings.theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;
    saveSettings({ theme: newTheme });
  };

  const activeIcon =
    settings.theme === THEMES.DARK ? <LightModeIcon /> : <DarkModeIcon />;

  return (
    <Tooltip title="Theme">
      <IconButton onClick={toggleTheme}>{activeIcon}</IconButton>
    </Tooltip>
  );
};

export default ThemeToggler;
