import { GoogleOAuthProvider } from "@react-oauth/google";
import { ExamPlaylistProvider } from "contexts/v2/ExamPlaylistContext";
import { FavoriteExamsProvider } from "contexts/v2/FavoriteExamsContext";
import { SnackBarProvider } from "contexts/v2/SnackBarContext";
import { TrashedExamsProvider } from "contexts/v2/TrashedExamsContext";
import i18n from "i18next";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { CatalogProvider } from "./contexts/v2/ExamCatalogContext";
import "./index.css";
import { En, Es } from "./languages";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: En,
    },
    es: {
      translation: Es,
    },
  },
  lng: "es",
  fallbackLng: "es",
});

const ExamPalace = (
  <BrowserRouter>
    <SettingsProvider>
      <AuthProvider>
        <CatalogProvider>
          <TrashedExamsProvider>
            <ExamPlaylistProvider>
              <FavoriteExamsProvider>
                <GoogleOAuthProvider
                  // clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                  clientId="521175665162-irkudb9gs86gfm99m4quqj0rfs6g8jc2.apps.googleusercontent.com"
                >
                  <SnackBarProvider>
                    <App />
                  </SnackBarProvider>
                </GoogleOAuthProvider>
              </FavoriteExamsProvider>
            </ExamPlaylistProvider>
          </TrashedExamsProvider>
        </CatalogProvider>
      </AuthProvider>
    </SettingsProvider>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(ExamPalace);
