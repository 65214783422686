import AppContainer from "./v1/Spacing/AppContainer/AppContainer";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const SplashScreen = () => {
  return (
    <AppContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "60vh",
          paddingTop: 8,
          flexDirection: "column",
        }}
      >
     
      </Box>
    </AppContainer>
  );
};

export default SplashScreen;
