import axiosConfig from "../axiosConfig";

class ClientRequest {
  method: string;
  url: string;
  data?: any;

  constructor(props: { method: string; url: string; data?: any }) {
    this.method = props.method;
    this.url = props.url;
    this.data = props.data;
  }

  async sendRequest() {
    try {
      const response: any = await axiosConfig.request({
        method: this.method,
        url: this.url,
        data: this.data,
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}

export default ClientRequest;
