import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../../hooks/useIsMobile";
import DirectionToggler from "../../../../common/DirectionToggler/DirectionToggler";
import ThemeToggler from "../../../../common/ThemeToggler/ThemeToggler";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import { ButtonBase } from "@mui/material";
import {  ChevronRight } from "@mui/icons-material";

const links = [
  {
    translation: "login",
    href: "/login",
  },
];

const HomeNavigation = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (isMobile) return null;

  return (
    <Stack
      paddingRight={{ xs: 1, sm: 2 }}
      direction="row"
      alignItems="center"
      columnGap={1}
    >
      <LanguageSelect />
      <Stack direction="row">
        <ThemeToggler />
        <DirectionToggler />
      </Stack>
      {links.map(({ translation, href }) => (
        <ButtonBase
          key={translation}
          onClick={() => navigate(href)}
          sx={{
            fontWeight: 700,
            backgroundColor: "background.dark",
            paddingY: 1,
            paddingX: 2,
            borderRadius: 2,
            color: "text.primary",
            minWidth:"120px"
          }}
        >
          {t(translation)}
        </ButtonBase>
      ))}
    </Stack>
  );
};

export default HomeNavigation;
