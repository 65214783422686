import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import navBarRoutes from "components/common/Navbar/navBarRoutes";
import useAuth from "hooks/useAuth";
import useSettings from "hooks/useSettings";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  cb?: () => void;
  disableHide?: boolean;
};

export const NavBarRouteItem = ({ route, cb, disableHide }: any) => {
  const { settings } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const isActive = route.withUserId
    ? location.pathname === `${route.path}/${user._id}`
    : location.pathname === route.path;

  if (settings.desktopMenu === "0" && !disableHide) {
    return (
      <Grid item xs={12} key={route.path}>
        <CardActionArea
          onClick={() => {
            cb && cb();
            setTimeout(
              () => {
                navigate(
                  route.profile || route.insights
                    ? `${route.path}/${user._id}`
                    : route.path
                );
              },
              cb ? 0 : 10
            );
          }}
          sx={{
            backgroundColor: isActive ? "action.selected" : "transparent",
            borderRadius: 0,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
              paddingX: 1,
              paddingY: 2,
              letterSpacing: 0.25,
              justifyContent: "center",
            }}
          >
            {route.icon}
          </CardContent>
        </CardActionArea>
      </Grid>
    );
  } else {
    return (
      <Grid item xs={12} key={route.path}>
        <CardActionArea
          onClick={() => {
            cb && cb();
            setTimeout(
              () => {
                navigate(
                  route.profile || route.insights
                    ? `${route.path}/${user._id}`
                    : route.path
                );
              },
              cb ? 0 : 10
            );
          }}
          sx={{
            backgroundColor: isActive ? "action.selected" : "transparent",
            borderRadius: 0,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
              paddingX: 1,
              paddingY: 2,
              letterSpacing: 0.25,
            }}
          >
            {route.icon}
            {
              <Typography
                sx={{ fontSize: "16px" }}
                fontWeight={route.profile ? 700 : 500}
              >
                {route.profile ? user.username : t(route.label)}
              </Typography>
            }
          </CardContent>
        </CardActionArea>
      </Grid>
    );
  }
};

const NavBarRouteItems = (props: Props) => (
  <>
    {navBarRoutes.map((route) => {
      return <NavBarRouteItem {...props} route={route} key={route.label} />;
    })}
  </>
);

export default NavBarRouteItems;
