import { SnackBarContext } from "contexts/v2/SnackBarContext";
import { useContext } from "react";

const useSnackBar = () => {
  const context = useContext(SnackBarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};
export default useSnackBar;
