import i18next from "i18next";

// This hook is helping to change language and set language to the local storage
const useChangeLanguage = () => {
  const changeLanguage = (language: string) => {
    i18next.changeLanguage(language);
    localStorage.setItem("lang", language);
  };
  return { changeLanguage };
};

export default useChangeLanguage;
