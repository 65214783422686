import { DialogTitle, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useDialog from "hooks/useDialog";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const CookieConcernDialog = () => {
  const { open, handleOpen, handleClose } = useDialog();
  const { t } = useTranslation();
  useEffect(() => {
    const cookieConcern = localStorage.getItem("cookieConcern");
    if (!cookieConcern) {
      handleOpen();
    }
  }, []);
  const handleAccept = () => {
    localStorage.setItem("cookieConcern", "accepted");
    handleClose();
  };
  const handleReject = () => {
    localStorage.setItem("cookieConcern", "rejected");
    handleClose();
  };
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogTitle>
          <Typography variant="h4">{t("cookie_concern.title")}</Typography>
        </DialogTitle>
        <DialogContent>{t("cookie_concern.text")}</DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReject} variant="outlined" color="error">
          {t("cookie_concern.reject")}
        </Button>
        <Button onClick={handleAccept} variant="outlined">
          {t("cookie_concern.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConcernDialog;
