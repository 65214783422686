import { Fragment, Suspense } from "react";
import SplashScreen from "../components/SplashScreen";
import { Route, Routes as BrowserRoutes } from "react-router-dom";
import userRoutesManager from "./UserRoutes";
import adminRoutesManager from "./AdminRoutes";
import homeRoutesManager from "./HomeRoutes";
import { GlobalStyles, useTheme } from "@mui/material";
import { Theme } from "../theme";

// Combine all the mapped routes into a single array
const reducedRoutes = [
  homeRoutesManager.mappedRoutes,
  userRoutesManager.mappedRoutes,
  adminRoutesManager.mappedRoutes,
].reduce((acc, routes) => acc.concat(routes), []);
// The Routes component
const Routes = () => {
  const theme: Theme = useTheme();
  return (
    // Use the Suspense component to render a fallback while lazy-loading components
    <Suspense fallback={<SplashScreen />}>
      {/* Use the BrowserRoutes component from react-router-dom to define the routes */}
      <BrowserRoutes>
        {/* Map the reducedRoutes array to Route components */}
        {reducedRoutes.map((route, index) => {
          // Get the guard component from the route, or use Fragment if not defined
          const Guard = route.guard || Fragment;
          // Get the layout component from the route, or use Fragment if not defined
          const Layout = route.layout || Fragment;
          // Get the component to render for the route
          const Component = route.element;
          const routeComponentProps = {
            key: `${index}-${route.path}`,
            // Combine the prefix and path properties to get the full path for the route
            path: `${route.prefix}${route.path}`,
            // Use the guard and layout components to wrap the route component
            element: (
              <Guard>
                <Layout>
                  <Component />
                </Layout>
              </Guard>
            ),
          };
          // Render a Route component for the current route
          return <Route {...routeComponentProps} />;
        })}
      </BrowserRoutes>
      <GlobalStyles
        styles={{
          html: { backgroundColor: theme.palette.background.default },
        }}
      />
    </Suspense>
  );
};

export default Routes;
