import { Alert, Snackbar } from "@mui/material";
import useSnackBar from "hooks/useSnackBar";

const UniversalSnackBar = () => {
  const {
    openAlert,
    vertical,
    horizontal,
    handleCloseAlert,
    alertSeverity,
    alertMessage,
  } = useSnackBar();
  return (
    <Snackbar
      open={openAlert}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={6000}
      onClose={handleCloseAlert}
      key={vertical + horizontal}
    >
      <Alert
        onClose={handleCloseAlert}
        severity={alertSeverity}
        sx={{ width: "100%" }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default UniversalSnackBar;
