import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../axiosConfig";
import { User } from "../contexts/AuthContext";
import useAuth from "../hooks/useAuth";

type Props = {
  children: React.ReactNode;
};

const AdminGuard = ({ children }: Props) => {
  const { saveUser, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleRefresh = async () => {
      const refreshToken = localStorage.getItem("exampalace_refresh_token");
      if (!refreshToken) {
        navigate("/login");
      } else {
        if (user.isAuth) return;
        try {
          const {
            data: { accessToken, user },
          } = (await axiosConfig.post("/auth/refresh", {
            refreshToken,
          })) as { data: { accessToken: string; user: User } };
          localStorage.setItem("exampalace_access_token", accessToken);
          saveUser({ ...user, isAuth: true });
          if (user.role !== "admin") {
            navigate("/app/dashboard");
          }
        } catch (error) {
          navigate("/login");
        }
      }
    };
    handleRefresh();
  }, []);

  return <>{children}</>;
};

export default AdminGuard;
