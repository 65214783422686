import { Container, Stack } from "@mui/material";
import Header from "components/common/Header/Header";
import React from "react";
import ProfileLayoutHeader from "./components/ProfileLayoutHeader";
import ProfileNavigationTabs from "./components/ProfileNavigationTabs";
import Footer from "components/Footer/Footer";

interface Props {
  children: React.ReactNode;
}

const ProfileLayout = ({ children }: Props) => {
  return (
    <>
      <Header isSideMenuButtonHidden={true} />
      <Stack
        sx={{
          marginTop: { xs: 1, md: 0 },
          overflowX: { xs: "hidden", lg: "auto" },
          paddingY: "56px",
        }}
      >
        <Stack sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Container maxWidth="lg">
            <ProfileLayoutHeader />
            <ProfileNavigationTabs />
          </Container>
          {/* Stack to extend all the way down, taking all the height */}
          <Stack sx={{ backgroundColor: "background.dark", minHeight: "71vh" }}>
            <Container maxWidth="lg" sx={{ marginY: 2 }}>
              {children}
            </Container>
          </Stack>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
};

export default ProfileLayout;
