import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AppContainer from "components/v1/Spacing/AppContainer/AppContainer";
import UniversalSnackBar from "components/v2/UniversalSnackBar/UniversalSnackBar";
import { useEffect } from "react";
import ScrollToTopButton from "./components/common/ScrollToTopButton/ScrollToTopButton";
import useCreateNotification from "./hooks/useCreateNotification";
import useSettings from "./hooks/useSettings";
import Routes from "./routes/routes";
import { createCustomTheme } from "./theme";
import handleLanguageLoad from "./utils/handleLanguageLoad";
import handleSavedQueries from "./utils/handleSavedQueries";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK || "");

const App = () => {
  const {
    settings: { direction, responsiveFontSizes, theme },
  } = useSettings();

  const { handleGetUnreadNotificationCount } = useCreateNotification();

  // Handling notification count load
  useEffect(() => {
    handleGetUnreadNotificationCount();
  }, [handleGetUnreadNotificationCount]);

  useEffect(() => {
    // Handling language change for entire application
    handleLanguageLoad();
    // Set an empty array if there are no previous search history
    handleSavedQueries();
  }, []);

  const myCustomTheme = createCustomTheme({
    direction,
    responsiveFontSizes,
    theme,
  });

  return (
    <ThemeProvider theme={myCustomTheme}>
      <Elements stripe={stripePromise}>
        <AppContainer>
          <Routes />
        </AppContainer>
      </Elements>
      <UniversalSnackBar />
      <ScrollToTopButton />
    </ThemeProvider>
  );
};

export default App;
