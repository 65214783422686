import { AlertColor } from "@mui/material";
import React, { createContext, useState } from "react";

interface ISnackbarContextValue {
  openAlert: boolean;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
  alertMessage: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  vertical: "top" | "bottom";
  horizontal: "left" | "center";
  handleCloseAlert: (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
  alertSeverity: AlertColor;
  setAlertSeverity: React.Dispatch<React.SetStateAction<AlertColor>>;
}

export const SnackBarContext = createContext<ISnackbarContextValue | undefined>(
  undefined
);

// Create a provider component for the Snackbar context
export const SnackBarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("success");
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  const alertPosition: any = {
    vertical: "bottom",
    horizontal: "right",
  };
  const { vertical, horizontal } = alertPosition;

  return (
    <SnackBarContext.Provider
      value={{
        openAlert,
        setOpenAlert,
        alertMessage,
        setAlertMessage,
        vertical,
        horizontal,
        handleCloseAlert,
        alertSeverity,
        setAlertSeverity,
      }}
    >
      {children}
    </SnackBarContext.Provider>
  );
};
