import React, { createContext, useCallback, useState } from "react";
import axiosConfig from "../../axiosConfig";

interface FavoriteExamsContextProps {
  favoriteExams: any[];
  totalFavoriteExams: number;
  loading: boolean;
  error: string;
  numberOfPages: number;
  handlePagination: (e: any, p: number) => void;
  setFavoriteExams: React.Dispatch<React.SetStateAction<any[]>>;
  fetchFavoriteExams: (userId: string) => Promise<void>;
}

const FavoriteExamsContext = createContext<
  FavoriteExamsContextProps | undefined
>(undefined);

interface FavoriteExamsProviderProps {
  children: React.ReactNode;
}

export const FavoriteExamsProvider: React.FC<FavoriteExamsProviderProps> = ({
  children,
}) => {
  const [favoriteExams, setFavoriteExams] = useState<any[]>([]);
  const [totalFavoriteExams, setTotalFavoriteExams] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const numberOfPages = Math.ceil(totalFavoriteExams / 6);

  const fetchFavoriteExams = useCallback(
    async (userId: string) => {
      setLoading(true);
      setError("");
      try {
        const response = await axiosConfig.get(
          `/favorite-exam?page=${page}&limit=${6}&userId=${userId}`
        );

        setFavoriteExams(response.data.favoriteExams);
        setTotalFavoriteExams(response.data.totalFavoriteExams);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    [page]
  );

  // Handling pagination
  const handlePagination = (e: any, p: number) => {
    setPage(p);
  };

  return (
    <FavoriteExamsContext.Provider
      value={{
        favoriteExams,
        totalFavoriteExams,
        loading,
        error,
        numberOfPages,
        handlePagination,
        setFavoriteExams,
        fetchFavoriteExams,
      }}
    >
      {children}
    </FavoriteExamsContext.Provider>
  );
};

export const FavoriteExamsConsumer = FavoriteExamsContext.Consumer;
export default FavoriteExamsContext;
