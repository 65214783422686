import type { FC, ReactNode } from "react";
import { createContext, useCallback, useState } from "react";

export interface IParticipatedBundles {
  bundleId: string;
  completedExamIds: string[];
}
export interface User {
  email?: string;
  role?: "user" | "admin" | "teacher";
  examsCreated?: number;
  username?: string;
  avatar?: string;
  isAuth?: boolean;
  isActive?: boolean;
  isVerified?: "pending" | "verified" | "failed";
  socialAuth?: boolean;
  _id?: string;
  totalPoints?: number;
  followerCount?: number;
  totalExamsTaken?: number;
  examsCompleted?: number;
  favoriteQuizzes?: string[];
  favoriteBlogs?: string[];
  participatedBundles?: IParticipatedBundles[];
  browsingData?: any[];
  lastOnline?: null | string;
  premiumStatus?: "active" | "expired" | "none";
  notifications?: number;
  premiumExpiration?: null | string;
  purchaseDate?: null | string;
  createdAt?: string;
}

export interface AuthContextValue {
  user: User;
  saveUser: (user: User) => void;
}

interface AuthProviderProps {
  children?: ReactNode;
}

export const initialUserState: User = {
  email: "",
  followerCount: 0,
  role: "user",
  examsCreated: 0,
  username: "Loading...",
  avatar: "",
  isAuth: false,
  isActive: true,
  socialAuth: false,
  _id: "",
  totalPoints: 0,
  totalExamsTaken: 0,
  examsCompleted: 0,
  favoriteQuizzes: [],
  favoriteBlogs: [],
  participatedBundles: [
    {
      bundleId: "",
      completedExamIds: [],
    },
  ],
  browsingData: [],
  lastOnline: null,
  premiumStatus: "none",
  notifications: 0,
  premiumExpiration: null,
  purchaseDate: null,
  createdAt: "",
};

const AuthContext = createContext<AuthContextValue>({
  user: initialUserState,
  saveUser: (user: User) => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<User>(initialUserState);
  // Saving updated users info
  const handleSaveUser = useCallback((user: User): void => {
    setState((prev) => ({ ...prev, ...user }));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: state,
        saveUser: handleSaveUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;
