import _ from "lodash";
import type { FC, ReactNode } from "react";
import { createContext, useEffect, useState } from "react";
import { THEMES } from "../constants";
import { ThemeConfig } from "../theme";

export interface SettingsContextValue {
  settings: ThemeConfig;
  saveSettings: (update: ThemeConfig) => void;
}

interface SettingsProviderProps {
  settings?: ThemeConfig;
  children?: ReactNode;
}

const defaultSettings: ThemeConfig = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: THEMES.DARK,
  desktopMenu: "1",
  sound: "true",
};

export const restoreSettings = (): ThemeConfig | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: ThemeConfig): void => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider: FC<SettingsProviderProps> = ({
  settings,
  children,
}) => {
  const [currentSettings, setCurrentSettings] = useState<ThemeConfig>(
    settings || defaultSettings
  );

  const handleSaveSettings = (update: ThemeConfig): void => {
    const mergedSettings = _.merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction || "ltr";
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
