import { LazyExoticComponent, ReactElement, ComponentType } from "react";
import addPrefixToObject from "../utils/addPrefixToObject";
import mapPropertiesToObjectArray from "../utils/mapPropertiesToObjectArray";

interface RoutesConfig {
  layout: ComponentType<any>;
  guard?: ComponentType<any>;
  prefix?: string;
}

interface URIs {
  [key: string]: string;
} 

interface RouteInput {
  path: string;
  element: LazyExoticComponent<React.ComponentType<any>> | (() => ReactElement);
  layout?: ComponentType<any> | null; // Adjusted to allow null
}

export interface IMappedRoutesOutput {
  path: string;
  element: LazyExoticComponent<React.ComponentType<any>> | (() => ReactElement);
  guard: ComponentType<any> | null; // Adjusted to allow null
  layout: ComponentType<any> | null; // Adjusted to allow null
  prefix?: string;
}

class RoutesManager {
  private readonly config: RoutesConfig;
  private readonly uris: URIs;
  private readonly routes: RouteInput[];
  public readonly allRoutes: URIs;
  public readonly mappedRoutes: IMappedRoutesOutput[];

  constructor(config: RoutesConfig, uris: URIs, routes: RouteInput[]) {
    this.config = config;
    this.config.prefix = this.config.prefix || "";
    this.uris = uris;
    this.routes = routes;
    this.allRoutes = addPrefixToObject(this.uris, this.config.prefix || "");
    this.mappedRoutes = mapPropertiesToObjectArray<
      RouteInput,
      IMappedRoutesOutput
    >(this.config, this.routes);
  }
}

export default RoutesManager;
