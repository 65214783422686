import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../components/common/Header/Header";
import NavBar from "../components/common/Navbar/NavBar";
import SessionExpirationDialog from "../components/common/SessionExpirationDialog/SessionExpirationDialog";
import useIsMobile from "../hooks/useIsMobile";
import useSettings from "hooks/useSettings";
interface Props {
  children: React.ReactNode;
}

const DashboardLayout = ({ children }: Props) => {
  const { isMobile } = useIsMobile();
  const [open, setOpen] = useState<boolean>(isMobile ? false : true);
  const {
    settings: { desktopMenu },
  } = useSettings();
  // Handling mobile responsiveness of the drawer
  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  // // Listen for incoming broadcasted notifications
  // useEffect(() => {
  //   if (user && user._id) {
  //     const socketListener = (receivedNotification: any) => {
  //       const usersNotification = {
  //         ...receivedNotification,
  //         user: user._id,
  //       };

  //       axiosConfig
  //         .post("/notification/", usersNotification)
  //         .then((res) => {
  //           saveUser({
  //             notifications: (user.notifications as number) + 1,
  //           });
  //         })
  //         .catch((error) => {});
  //     };

  //     // Clear previous event listeners before setting a new one
  //     socket.off("receive-notification");
  //     socket.on("receive-notification", socketListener);

  //     return () => {
  //       socket.off("receive-notification", socketListener);
  //     };
  //   }
  // }, [user]);

  return (
    <>
      <Header />
      {<NavBar open={open} setOpen={setOpen} />}
      <Stack
        sx={{
          overflowX: { xs: "hidden", md: "auto" },
          marginLeft: isMobile ? "4px" : desktopMenu === "0" ? "72px" : "256px",
          marginRight: "4px",
          paddingY: "56px",
          marginTop: { xs: 1, md: 0},
        }}
      >
        {children}
      </Stack>
      <SessionExpirationDialog />
    </>
  );
};

export default DashboardLayout;
