import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axiosConfig from "../../axiosConfig";

interface CatalogProviderProps {
  children?: ReactNode;
}

interface CatalogContextState {
  items: any[];
  error: boolean;
  loading: boolean;
  fetchExams: (params?: any, setData?: boolean) => Promise<any>;
  setItems: any;
  setNextPageItems: any;
  fetched: boolean;
  setLoading: (value: boolean) => void;
  setEndOfCatalog: (value: boolean) => void;
  setSearchOpen: () => void;
  activeFilters: string[];
  toggleActiveFilters: (filter: string) => void;
  searchOpen: boolean;
  endOfCatalog: boolean;
}

const initialState = {
  items: [] as string[],
  error: false,
  loading: false,
  fetchExams: async () => {},
  setItems: () => {},
  setNextPageItems: () => {},
  setLoading: () => {},
  setEndOfCatalog: () => {},
  setSearchOpen: () => {},
  toggleActiveFilters: () => {},
  fetched: false,
  searchOpen: false,
  activeFilters: ["all"],
  endOfCatalog: false,
};

const LOCAL_STORAGE_KEY = "catalogActiveFilters"; // Key for localStorage

const CatalogContext = createContext<CatalogContextState>(initialState);

const CatalogProvider: FC<CatalogProviderProps> = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    // Load activeFilters from localStorage on component mount
    const storedFilters = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedFilters) {
      setState((prev) => ({
        ...prev,
        activeFilters: JSON.parse(storedFilters),
      }));
    }
  }, []);

  const saveFiltersToLocalStorage = (filters: string[]) => {
    // Save activeFilters to localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters));
  };

  const setEndOfCatalog = (value: boolean) =>
    setState((prev) => ({ ...prev, endOfCatalog: value }));
  const setSearchOpen = () =>
    setState((prev) => ({ ...prev, searchOpen: !prev.searchOpen }));
  const setLoading = (val: boolean) =>
    setState((prev) => ({ ...prev, loading: val }));

  const setFetched = (val: boolean) =>
    setState((prev) => ({ ...prev, fetched: val }));

  const setItems = (val: any[]) =>
    setState((prev) => ({ ...prev, items: val }));
  const setNextPageItems = (val: any[]) =>
    setState((prev) => ({ ...prev, items: [...prev.items, ...val] }));

  const setError = (val: boolean) =>
    setState((prev) => ({ ...prev, error: val }));

  const toggleActiveFilters = (filter: string) => {
    setState((prev) => {
      const { activeFilters } = prev;

      // If the incoming filter is anything but "all", turn off the "all" filter
      const updatedFilters =
        filter === "all"
          ? [filter] // If "all" is selected, set it as the only active filter
          : activeFilters.includes("all")
          ? [filter] // If "all" was active, replace it with the selected filter
          : activeFilters.includes(filter)
          ? activeFilters.filter((f) => f !== filter) // If filter is already active, remove it
          : [...activeFilters, filter]; // Otherwise, add the filter

      // If no filters are selected, set "all" as the only active filter
      const finalFilters =
        updatedFilters.length === 0 ? ["all"] : updatedFilters;

      // Perform any additional logic here if needed

      saveFiltersToLocalStorage(finalFilters); // Save the updated filters to localStorage

      return { ...prev, activeFilters: finalFilters };
    });
  };

  const fetchExams = useCallback(async (queries?: any, setData?: boolean) => {
    try {
      if (!setData) {
        setLoading(true);
      }
      const response = await axiosConfig.get(`/exam${queries || ""}`);
      if (!setData) {
        setFetched(true);
      }
      return response;
    } catch (error) {
      if (!setData) setError(true);
    } finally {
      if (!setData) setLoading(false);
    }
  }, []);

  return (
    <CatalogContext.Provider
      value={{
        ...state,
        setItems,
        setNextPageItems,
        setEndOfCatalog,
        fetchExams,
        setLoading,
        toggleActiveFilters,
        setSearchOpen,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};

const useCatalog = () => {
  const context = useContext(CatalogContext);
  if (!context) {
    throw new Error("useCatalog must be used within a CatalogProvider");
  }
  return context;
};

export { CatalogProvider, useCatalog };
