import { Box, Stack } from "@mui/material";
import { languages } from "../../../../../constants";
import useChangeLanguage from "../../../../../hooks/useChangeLanguage";
import SmallButton from "components/v2/common/Buttons/ButtonsSmall";
import { useTranslation } from "react-i18next";

const LanguageSelect = ({ onSelect }: { onSelect?: (val: string) => void }) => {
  const { changeLanguage } = useChangeLanguage();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        display: "flex",
        margin: 1,
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {languages.map((_lang) => (
        <SmallButton
          value={_lang.code}
          key={_lang.country_code}
          onClick={() => {
            changeLanguage(_lang.code);
            onSelect && onSelect(_lang.code);
          }}
          id={_lang.code}
          sx={{
            width: "120px",
            border: "1px solid",
            borderColor:
              currentLanguage === _lang.code ? "action.disabled" : "transparent",
          }}
        >
          <Box sx={{ mr: 1 }} className={`fi fi-${_lang.country_code} fis`} />{" "}
          {_lang.name}
        </SmallButton>
      ))}
    </Stack>
  );
};

export default LanguageSelect;
