import { MouseEvent } from "react";
import Zoom from "@mui/material/Zoom";
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";

type Props = {
  toggleMenu: (event: MouseEvent<HTMLElement>) => void;
  isOpen: boolean;
};

const MobileMenuButton = ({ toggleMenu, isOpen }: Props) => (
  <IconButton
    size="large"
    color="primary"
    aria-haspopup="true"
    onClick={toggleMenu}
    aria-label="Toggle Menu"
    aria-controls="Toggle Menu"
  >
    <Zoom in key={isOpen.toString()}>
      {isOpen ? <Close color="primary" /> : <MenuIcon color="primary" />}
    </Zoom>
  </IconButton>
);

export default MobileMenuButton;
