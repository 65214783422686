import { Star } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const BasicPremiumInvitation = () => {
  const { t } = useTranslation();
  const {
    user: { premiumStatus },
  } = useAuth();
  const navigate = useNavigate();
  if (premiumStatus === "active") return null;
  return (
    <Stack
      display="flex"
      alignItems="center"
      justifyContent="center"
      direction="column"
      rowGap={2}
      flexWrap="wrap"
      paddingX={3}
    >
      <Typography align="center">
        {t("activate")} <span style={{ fontWeight: 900 }}>{t("premium")}</span>{" "}
        {t("get_access")} {t("all_exams")}
      </Typography>
      <Button
        sx={{ height: "50px" }}
        fullWidth
        color="inherit"
        startIcon={<Star />}
        onClick={() => navigate("/app/checkout")}
      >
        {t("get_permium")}
      </Button>
    </Stack>
  );
};

export default BasicPremiumInvitation;
