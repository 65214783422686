import i18next from "i18next";

export const LANG_KEY = "lang";
export const DEFAULT_LANG = "es";

const handleLanguageLoad = () => {
  const currentLanguage = localStorage.getItem(LANG_KEY) || DEFAULT_LANG;
  i18next.changeLanguage(currentLanguage);
};

export default handleLanguageLoad;
