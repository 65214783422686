import { IconButton, Stack } from "@mui/material";
import ExamSearchBar from "components/v2/common/ExamSearchBar";
import useIsMobile from "../../../hooks/useIsMobile";
import HideOnScrollHeader from "../../HideOnScrollHeader";
import MainMenuDialog from "components/v2/common/MainMenuDialog";
import { Menu, MenuSharp, Search } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useCatalog } from "contexts/v2/ExamCatalogContext";
import MainLogo from "components/v1/Layouts/HomeLayout/Logo/Logo";
import useSettings from "hooks/useSettings";

const MobileSearchBarTrigger = () => {
  const { setSearchOpen } = useCatalog();
  const location = useLocation();

  if (location.pathname !== "/app/exam/browse") {
    return null;
  }

  return (
    <IconButton
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setSearchOpen();
      }}
      sx={{
        display: { xs: "flex", sm: "flex", md: "none" },
      }}
    >
      <Search />
    </IconButton>
  );
};
const Header = ({
  isSideMenuButtonHidden,
}: {
  isSideMenuButtonHidden?: boolean;
}) => {
  const { isMobile } = useIsMobile();
  const { settings, saveSettings } = useSettings();

  const toggleDesktopMenu = () => {
    const newDirection = settings.desktopMenu === "1" ? "0" : "1";
    saveSettings({ desktopMenu: newDirection });
  };

  return (
    <HideOnScrollHeader>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          {!isMobile && !isSideMenuButtonHidden && (
            <IconButton onClick={toggleDesktopMenu}>
              <MenuSharp />
            </IconButton>
          )}
          <MainLogo />
        </Stack>
        <ExamSearchBar />
        <Stack direction="row" alignItems={"center"}>
          <MobileSearchBarTrigger />
          <MainMenuDialog />
        </Stack>
      </Stack>
    </HideOnScrollHeader>
  );
};

export default Header;
