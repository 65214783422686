import { Avatar } from "@mui/material";
import useAuth from "hooks/useAuth";

const BadgeIcon = () => {
  const { user } = useAuth();
  return (
    <>
      <Avatar src={user.avatar} />
    </>
  );
};

export default BadgeIcon;
