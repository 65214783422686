import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import { IconButton, Tooltip } from "@mui/material";
import useSettings from "../../../hooks/useSettings";

const DirectionToggler = () => {
  const { settings, saveSettings } = useSettings();

  const toggleDirection = () => {
    const newDirection = settings.direction === "ltr" ? "rtl" : "ltr";
    saveSettings({ direction: newDirection });
  };
  const activeDirection =
    settings.direction === "ltr" ? <JoinLeftIcon /> : <JoinRightIcon />;
  return (
    <Tooltip title="Direction">
      <IconButton onClick={toggleDirection}>{activeDirection}</IconButton>
    </Tooltip>
  );
};

export default DirectionToggler;
