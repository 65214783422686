import { useCallback, useEffect, useState } from "react";
import axiosConfig from "../axiosConfig";
import getUnreadNotificationsCount from "../services/Notifications/getUnreadNotificationsCount";
import { INotification } from "../views/UserNotifications/UserNotifications";
import useAsync from "./useAsync";
import useAuth from "./useAuth";

// This hook is helping to create notification
const useCreateNotification = () => {
  const { user, saveUser } = useAuth();
  const { execute, response } = useAsync<{ count: number }>();
  const handleGetUnreadNotificationCount = useCallback(async () => {
    if (!user.isAuth) return;
    execute(() => getUnreadNotificationsCount(user._id as string));
  }, [user.isAuth, user._id, execute]);

  useEffect(() => {
    saveUser({ notifications: response?.count });
  }, [response, saveUser]);
  const [notificationResponse, setNotificationResponse] = useState<string>("");
  const [error, setError] = useState<string>("");
  useEffect(() => {
    handleGetUnreadNotificationCount();
  }, [handleGetUnreadNotificationCount, notificationResponse]);

  const handleCreateNotification = (data: INotification) => {
    axiosConfig
      .post("/notification/", {
        data,
      })
      .then((response: any) => {
        setNotificationResponse(response);
      })
      .catch((error) => {
        setError(error);
      });
  };
  return {
    error,
    handleCreateNotification,
    handleGetUnreadNotificationCount,
  };
};

export default useCreateNotification;
