import { Avatar, Grid, Stack, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type Props = {};
const avatarSize = "168px";
const [height, width] = new Array(2).fill(avatarSize);

const ProfileLayoutHeader = (props: Props) => {
  const { user } = useAuth();
  const [{ avatar, username, friendCount }, setProfile] = useState({
    avatar: "",
    username: "",
    friendCount: 0,
  });
  const { userId } = useParams();
  useEffect(() => {
    if (userId === user._id) {
      setProfile({
        avatar: user.avatar!,
        username: user.username!,
        friendCount: 0,
      });
    }
    else{

    }
  }, [userId, user._id, user.avatar, user.username]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Avatar src={avatar} sx={{ height, width }} />
          <Stack>
            <Typography
              color="textPrimary"
              sx={{ fontSize: "32px", fontWeight: 700 }}
            >
              {username}
            </Typography>
            <Typography
              color="textSecondary"
              fontWeight={700}
              sx={{ fontSize: "15px" }}
            >
              {friendCount} friends
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileLayoutHeader;
