import { capitalize, sample } from "lodash";
import addPrefixToObject from "../utils/addPrefixToObject";

export const APP_TAB_NAME = "ExamPalace.com";

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

export const components = {
  BREAD_CRUMBS: "BREAD_CRUMBS",
  TITLE: "TITLE",
  SUBTITLE: "SUBTITLE",
  PARAGRAPH: "PARAGRAPH",
  FLASHCARD_GRID: "FLASHCARD_GRID",
  BASIC_TABLE: "BASIC_TABLE",
  MULTIPLE_CHOICE_QUIZ: "MULTIPLE_CHOICE_QUIZ",
};

export const categories = {
  grammar: "Gramatica",
  vocabulary: "Vocabulary",
  reading: "Reading",
};

export const QUESTION_TYPES = {
  "Multiple Choice": "Multiple Choice",
};

export const levels = {
  a1: "A1",
  a2: "A2",
  b1: "B1",
  b2: "B2",
  c1: "C1",
  c2: "C2",
  toefl: "TOEFL",
};

export const difficulties = {
  low: "Bajo",
  medium: "Medio",
  high: "Alto",
  master: "Maestro",
};
export const languages = [
  {
    code: "es",
    name: "Español",
    country_code: "es",
  },
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
];
export const avatars = [
  "/static/avatars/bear.png",
  "/static/avatars/cheetah.png",
  "/static/avatars/chimpanzee.png",
  "/static/avatars/crocodile.png",
  "/static/avatars/dolphin.png",
  "/static/avatars/elephant.png",
  "/static/avatars/fox.png",
  "/static/avatars/giraffe.png",
  "/static/avatars/gorilla.png",
  "/static/avatars/hippo.png",
  "/static/avatars/horse.png",
  "/static/avatars/kangaroo.png",
  "/static/avatars/koala.png",
  "/static/avatars/lion.png",
  "/static/avatars/octopus.png",
  "/static/avatars/panda.png",
  "/static/avatars/penguin.png",
  "/static/avatars/tiger.png",
  "/static/avatars/whale.png",
  "/static/avatars/zebra.png",
];

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!])(?=.*[a-zA-Z])(?!.*\s).{8,}$/;

export const motivationalPhrases = [
  "No tengas miedo de cometer errores, son los escalones hacia el éxito.",
  "Aprender un nuevo idioma requiere tiempo y paciencia, ¡sé persistente!",
  "Cree en ti mismo y en tus habilidades, ¡puedes hacerlo!",
  "Cada día es una nueva oportunidad de mejorar tus habilidades en inglés.",
  "Acepta desafíos y nuevas experiencias, te ayudarán a crecer.",
  "Tu arduo trabajo y dedicación darán sus frutos al final.",
  "Rodéate de gente que te apoye y te anime en tu viaje de aprendizaje de idiomas.",
  "Nunca te rindas, sigue empujándote a alcanzar tus metas.",
  "La práctica hace al maestro, sigue adelante y verás mejoras.",
  "Mantente positivo, diviértete y disfruta del proceso de aprendizaje.",
  "Habla inglés todo lo que puedas con amigos y familiares",
  "Ve películas y programas de televisión en inglés con subtítulos en español",
  "Escucha música y canta en inglés",
  "Lee libros, periódicos y artículos en inglés",
  "Aprende nuevas palabras y frases en inglés cada día",
  "Practica escribir en inglés todos los días",
  "Únete a un grupo de conversación en inglés",
  "Tomar clases de inglés en una escuela o con un tutor privado",
  "Viaja a un país donde se hable inglés",
  "Ve vídeos en inglés en YouTube o en otras plataformas en línea",
];

export const SUBSCRIPTION_REQUIRED_MESSAGE = `Hola! Parece que aún no has utilizado una de tus pruebas gratuitas disponibles. ¿Te gustaría usar una para acceder a nuestro contenido premium? De esta manera, podrás probar nuestros servicios sin ningún costo adicional. ¡No pierdas esta oportunidad! Si estás interesado, por favor haz clic en el botón de "Usar Prueba Gratuita" y sigue las instrucciones para comenzar. ¡Gracias por ser parte de nuestra comunidad!`;

export const USER_ROUTES_PATH_PREFIX = "/app";

export const USER_URIS = {
  TAKE_EVALUATION: "/cefr-evaluation/take",
  BROWSE_EXAMS: "/exam/browse",
  FAVORITE_EXAMS: "/favorite-exams",
  EXAM_PLAYLIST: "/exam/playlist",
  TAKE_EXAM: "/exam/take",
  TAKE_EXAM_FROM_PLAYLIST: "/exam/take-from-playlist",
  RANKING: "/ranking",
  HISTORY: "/history",
  BUNDLES: "/bundles",
  SEARCH: "/exam/search",
  BUNDLE: "/bundle",
  BLOGS: "/blogs",
  BLOGS_BY_CATEGORY: "/blogs/category",
  FAVORITE_BLOGS: "/blogs/favorite",
  BLOG: "/blog",
  SETTINGS: "/settings",
  PRODUCTS: "/products",
  PROFILE: "/profile",
  INSIGHTS: "/insights",
  CHECKOUT: "/checkout",
  PROMO_CODE: "/promo-code",
  NOTIFICATIONS: "/notifications",
  PLANS: "/plans",
  ACHIEVEMENTS: "/achievements",
  CART: "/cart",
  NEWS_FEED: "/feed",
  SKILL_TREE: "/skills",
  CREATE_EXAM: "/create-exam",
  SEARCH_USERS: "/search-users",
  CEFR_EVALUATION: "/cefr-evaluation",
  LIST_FRIENDS: "/list-friends",
  VOCABULARIES: "/vocabularies",
  CREATE_SENTENCE: "/create-sentence",
  SENTENCES: "/sentences",
  RECENT_EXAMS: "/recent-exams"
};

export const USER_ROUTES: typeof USER_URIS = addPrefixToObject(
  USER_URIS,
  USER_ROUTES_PATH_PREFIX
);

export const voices = [
  {
    name: "English US (Male)",
    value: "Microsoft Mark - English (United States)",
  },
  {
    name: "English US (Female)",
    value: "Google US English",
  },
  {
    name: "English UK (Male)",
    value: "Google UK English Male",
  },
  {
    name: "English UK (Female)",
    value: "Google UK English Female",
  },

  {
    name: "Español",
    value: "Google español",
  },
  {
    name: "Español de Estados Unidos",
    value: "Google español de Estados Unidos",
  },
];

export const questionCategories = [
  {
    name: "Grammar",
    subcategories: [
      "Parts of Speech (Nouns, Verbs, Adjectives, etc.)",
      "Tenses (Present, Past, Future)",
      "Sentence Structure",
      "Punctuation and Capitalization",
      "Subject-Verb Agreement",
    ],
  },
  {
    name: "Vocabulary",
    subcategories: [
      "Word Definitions",
      "Synonyms/Antonyms",
      "Word Associations",
      "Idioms and Phrases",
      "Prefixes and Suffixes",
    ],
  },
  {
    name: "Reading Comprehension",
    subcategories: [
      "Passages with Questions",
      "Inference and Interpretation",
      "Contextual Meaning",
      "Main Idea and Supporting Details",
    ],
  },
  {
    name: "Listening",
    subcategories: [
      "Audio Clips with Questions",
      "Dialogues and Conversations",
      "Understanding Accents and Pronunciation",
      "Note-taking and Summarizing",
    ],
  },
  {
    name: "Writing",
    subcategories: [
      "Sentence Completion",
      "Essay Writing Prompts",
      "Sentence Correction",
      "Paragraph Development",
    ],
  },
  {
    name: "Speaking",
    subcategories: [
      "Pronunciation Exercises",
      "Oral Expression Activities",
      "Dialogues and Role-Playing",
      "Public Speaking Skills",
    ],
  },
  {
    name: "Cultural Awareness",
    subcategories: [
      "Customs and Traditions",
      "Cultural Practices",
      "Geography and History",
      "Cultural Etiquette",
    ],
  },
  {
    name: "Test Preparation",
    subcategories: [
      "TOEFL (Test of English as a Foreign Language)",
      "IELTS (International English Language Testing System)",
      "Cambridge English exams (PET, FCE, CAE, etc.)",
      "Practice Tests and Strategies",
    ],
  },
];

export const questionCategoriesArray = questionCategories.map(
  (category) => category.name
);
export const offensiveWords = ["Shit", "Bitch", "Damn", "Fuck", "Fucking"];
// Create sentence phrases
export const badWordPreventionPhrases = [
  "Let's keep it clean and avoid offensive language! 🚫",
  "Remember to be respectful and steer clear of offensive words! 🙅‍♂️",
  "Please use language that is inclusive and respectful to all! 🌍",
  "Keep the conversation positive by avoiding offensive language! 🌟",
  "Let's make this a safe space by refraining from offensive words! 🛡️",
  "Mind your language and choose words that uplift others! 🤐",
  "Spread kindness by avoiding offensive language! 🌺",
  "Respect others by refraining from using offensive words! 🤝",
  "Choose your words wisely and avoid anything that may cause harm! ⚠️",
  "Remember, words have power, so let's use them for good! 💬",
];
export const getEncouragingPhrase = (word: string) => {
  word = capitalize(word);
  word = `<b>${word}</b>`;
  const encouragingPhrases = [
    `Let's craft a sentence using ${word}! ✍️`,
    `Time to concoct a sentence with ${word}! 📝`,
    `Get creative and fashion a sentence with ${word}! 💡`,
    `Put your writing skills to the test and compose a sentence with ${word}! 🖊️`,
    `Challenge yourself to come up with a sentence featuring ${word}! 🧠`,
    `It's sentence-building time! Use ${word} in a sentence! 🏗️`,
    `Flex your linguistic muscles and form a sentence with ${word}! 💪`,
    `Unleash your imagination and weave ${word} into a sentence! 🌈`,
    `Let's see what kind of sentence you can make with ${word}! 🤔`,
    `Take on the challenge and incorporate ${word} into a sentence! 🚀`,
  ];
  return sample(encouragingPhrases);
};
export const getWordNotFoundPharase = (word: string) => {
  const wordNotFoundPhrases = [
    `Oops! Looks like you forgot to use ${word} in your sentence. 🤔`,
    `Let's try again! Please make sure to include ${word} in your sentence. 🔄`,
    `Don't forget to use ${word} in your sentence! Keep going! 💡`,
    `Hmm... ${word} seems to be missing from your sentence. Can you add it? 🕵️‍♂️`,
    `It's important to include ${word} in your sentence. Give it another shot! 🔍`,
    `Remember to incorporate ${word} into your sentence for clarity. 📝`,
    `Include ${word} in your sentence to convey your message effectively. 🗣️`,
    `Let's make sure ${word} is part of your sentence. You're almost there! 🎯`,
    `Your sentence could use ${word} to make it complete. Keep going! 🚀`,
    `Add ${word} to your sentence to make it more impactful! You can do it! 💪`,
  ];
  return sample(wordNotFoundPhrases);
};
export const alreadyCreatedPhrases = [
  "Looks like someone beat you to it! Please try another sentence. 🕒",
  "Oops! This sentence has already been created. Can you think of another one? 🤷‍♂️",
  "Sorry, but someone else has already used this sentence. Give it another shot! 🎯",
  "Oh no! It seems this sentence is taken. Try a different one! 🔄",
  "You're not the first one! This sentence is already in use. Try again! 🚫",
  "Seems like this sentence is already on the board! Give it another go! 🔄",
  "Looks like someone else has beaten you to the punch with this sentence! 🥊",
  "This sentence has already been claimed! Try coming up with a new one! 🛑",
  "Sorry, this sentence is already in the pool. Try your luck with another one! 🎲",
  "Oops! It appears someone has already used this sentence. Time for a new idea! 🌟",
];
export const congratulationPhrases = [
  "Bravo! Your sentence shines bright! Let's share it with the world! 🎉🌍",
  "Well done! Your sentence is a masterpiece! Time to hit publish and spread the joy! 👏🚀",
  "Hooray! Your sentence is a winner! Publish it now and let the celebrations begin! 🥳🎊",
  "You're on fire! Your sentence is fantastic! Now, let's publish and ignite inspiration! 🔥💡",
  "Way to go! Your sentence rocks! Publish it and empower others with your words! 💪🌟",
  "Magnificent work! Your sentence shines brightly! Hit publish and let it dazzle the world! 🌟✨",
  "Hip, hip, hooray! Your sentence is superb! Publish it and let the party begin! 🎈🎈",
  "Outstanding performance! Your sentence is a champion! Publish it and inspire greatness! 🏆🌠",
  "Splendid! Your sentence is stellar! Publish it now and let it light up the sky! ⭐🌌",
  "Bravo! Your sentence is excellent! Hit publish and share your brilliance with the world! 👍📣",
];
export const errorPhrases = [
  "Oops! Something went wrong. Please try again later. 😕",
  "Uh-oh! It seems there was an error. Please refresh the page and try again. 🔄",
  "Sorry, an error occurred. Please check your connection and try again. 🚫",
  "Oh no! There was an unexpected error. Please try a different action. 😟",
  "Oops! It looks like something went awry. Please try again. 🤷‍♂️",
  "Error: Unable to process your request. Please try again later. ⚠️",
  "Sorry, there was an error processing your request. Please try again. 🛑",
  "Whoops! An error occurred. Please try again later. 🤔",
  "Error: Unable to complete the operation. Please try again later. ❌",
  "Sorry, there seems to be an error. Please try your request again. 🤦‍♂️",
];
export const publishedPhrases = [
  "Great job! 🎉 Your sentence has hit the press!",
  "Bravo! 🌟 Your sentence is now out in the wild!",
  "Well done! 🚀 Your sentence is officially published!",
  "Congratulations! 📚 Your sentence has made it into print!",
  "Fantastic! 📰 Your sentence has been released to the world!",
  "Incredible work! 🌈 Your sentence is now live!",
  "Amazing! 🌟 Your sentence has been published for all to see!",
  "Outstanding! 📝 Your sentence is now part of the record!",
  "Superb! 🎊 Your sentence has been shared with the world!",
  "Terrific! 🎈 Your sentence is now in print for all to read!",
];
