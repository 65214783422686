import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { ReactNode } from "react";

interface SmallButtonProps extends ButtonBaseProps {
  children: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const SmallButton = ({
  children,
  startIcon,
  endIcon,
  sx,
  ...rest
}: SmallButtonProps) => {
  return (
    <ButtonBase
      {...rest}
      sx={{
        fontWeight: 700,
        fontSize: "14px",
        backgroundColor: "background.dark",
        color: "text.primary",
        paddingX: 2,
        paddingY: 1,
        borderRadius: 2,
        width: "100%",
        ":hover": {
          backgroundColor: "action.selected",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        ...sx,
      }}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </ButtonBase>
  );
};

export default SmallButton;
