import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import axiosConfig from "axiosConfig";
import { User, initialUserState } from "contexts/AuthContext";
import useAuth from "hooks/useAuth";
import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const SessionExpirationDialog = () => {
  const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(30);
  const { saveUser } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const checkTokenExpiration = () => {
    const accessToken = localStorage.getItem("exampalace_access_token");

    if (accessToken) {
      const decodedToken: any = jwtDecode(accessToken);

      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (decodedToken.exp < currentTimestamp) {
        setIsSessionExpired(true);
      } else {
        setIsSessionExpired(false);
      }
    } else {
      setIsSessionExpired(true);
    }
  };
  // Checking the token expiration timestamp every second
  useEffect(() => {
    const intervalId = setInterval(checkTokenExpiration, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const handleSignOut = () => {
    saveUser(initialUserState);
    localStorage.removeItem("exampalace_refresh_token");
    localStorage.removeItem("exampalace_access_token");
    setIsSessionExpired(false);
    navigate("/login");
    setCountdown(30);
  };
  const handleImHere = useCallback(async () => {
    const refreshToken = localStorage.getItem("exampalace_refresh_token");
    if (!refreshToken) {
      navigate("/login");
    } else {
      try {
        const {
          data: { accessToken, user: fetchedUser, ranking },
        } = (await axiosConfig.post("/auth/refresh", {
          refreshToken,
        })) as {
          data: {
            accessToken: string;
            user: User;
            ranking: { examsCompleted: number; points: number };
          };
        };
        localStorage.setItem("exampalace_access_token", accessToken);
        saveUser({
          ...fetchedUser,
          isAuth: true,
          totalExamsTaken: ranking.examsCompleted,
          totalPoints: ranking.points,
        });
      } catch (error) {
        navigate("/login");
      }
    }
  }, [navigate, saveUser]);
  useEffect(() => {
    let timer: any;
    if (isSessionExpired) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(timer);
            handleSignOut();
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isSessionExpired]);

  return (
    <Dialog open={isSessionExpired}>
      <DialogContent>
        <Typography variant="h3">
          {t("sessionExpiration.still_there")}
        </Typography>
        <Typography variant="body1">
          {t("sessionExpiration.if_not")} {countdown}{" "}
          {t("sessionExpiration.seconds")}.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleImHere} variant="outlined">
          {t("sessionExpiration.im_here")}
        </Button>
        <Button onClick={handleSignOut} variant="outlined" color="error">
          {t("sessionExpiration.sign_out")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionExpirationDialog;
