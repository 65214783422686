import { Stack } from "@mui/material";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

const MainLogo = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  return (
    <Stack sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <img
        src="/ExamPalaceLogo.png"
        alt="Exampalace_logo"
        style={{ width: "180px", height: "56px", cursor: "pointer" }}
        onClick={() => navigate(user.isAuth ? "/app" : "/")}
      />
    </Stack>
  );
};

export default MainLogo;
