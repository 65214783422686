import {
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useLocation, useNavigate } from "react-router-dom";
import useIsMobile from "../../../hooks/useIsMobile";
import BasicPremiumInvitation from "../Greetings/BasicPremiumInvitation";
import NavbarFooter from "./NavbarFooter";
import navBarRoutes from "./navBarRoutes";
import { useTranslation } from "react-i18next";
import useAuth from "hooks/useAuth";
import NavBarRouteItems from "components/v2/common/NavBarRouteItems";
import useSettings from "hooks/useSettings";

interface Props {
  setOpen: any;
  open: boolean;
}
const Navbar = ({ open, setOpen }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    settings: { desktopMenu },
  } = useSettings();
  const desktopOpen = desktopMenu === "1";
  return (
    <Drawer
      open={open}
      variant="persistent"
      PaperProps={{
        sx: {
          paddingTop: 8,
          paddingX: 1,
          width: desktopOpen ? "256px" : "64px",
          zIndex: 1,
          backgroundColor: "background.paper",
          boxShadow: "none",
          border: "none",
        },
      }}
      onClose={() => setOpen(false)}
    >
      <Grid container spacing={0} zIndex={2} sx={{ marginTop: 1 }}>
        <Stack direction="column" height="92vh" justifyContent="space-between">
          <Grid item xs={12}>
            <NavBarRouteItems />
            {desktopOpen && (
              <Grid item xs={12}>
                <BasicPremiumInvitation />
              </Grid>
            )}
          </Grid>
          {desktopOpen && (
            <Grid
              container
              spacing={1}
              zIndex={2}
              sx={{ marginTop: 1, alignItems: "flex-end", paddingLeft: 2 }}
            >
              <NavbarFooter />
            </Grid>
          )}
        </Stack>
      </Grid>
    </Drawer>
  );
};

export default Navbar;
