import ClientRequest from "../APIService";

export interface IGetUnreadNotificationsCountResponse {
  count: number;
}

const getUnreadNotificationsCount = async (
  userId: string
): Promise<IGetUnreadNotificationsCountResponse> => {
  const clientRequest = new ClientRequest({
    method: "get",
    url: `/notification/unread/${userId}`,
  });
  return await clientRequest.sendRequest();
};

export default getUnreadNotificationsCount;
