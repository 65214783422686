import { Search } from "@mui/icons-material";
import { ButtonBase, Stack } from "@mui/material";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import useIsMobile from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const borderRadius = "40px";
const route = "/app/exam/search/";
const ExamSearchBar = (props: InputBaseProps) => {
  const { isMobile } = useIsMobile();
  const [query, setQuery] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();

  // Set the initial search query based on the current location
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes(route)) {
      const searchParam = currentPath.replace(route, "");
      setQuery(decodeURIComponent(searchParam));
    }
  }, [location.pathname]);

  const handleSearch = () => {
    if (!query.trim().length) return;
    const encodedQuery = encodeURIComponent(query);
    navigate(`/app/exam/search/${encodedQuery}`);
  };

  if (isMobile) return null;
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          marginBottom: { xs: 2, md: 0, sm: 2 },
          paddingRight: 8,
        }}
      >
        <InputBase
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          autoFocus
          placeholder="Search"
          {...props}
          sx={{
            paddingLeft: 1,
            border: "1px solid",
            borderColor: "action.disabledBackground",
            borderTopLeftRadius: borderRadius,
            minWidth:"520px",
            borderBottomLeftRadius: borderRadius,
            transition: "border-color 0.2s",
            "&.Mui-focused": {
              borderColor: "primary.main",
              transition: "border-color 0.2s",
            },
            minHeight: "40px",
            "&::placeholder": { color: "red" },
          }}
        />
        <ButtonBase
          onClick={handleSearch}
          sx={{
            minHeight: "40px",
            border: "1px solid",
            borderColor: "action.disabledBackground",
            borderLeft: "0px",
            borderTopRightRadius: borderRadius,
            borderBottomRightRadius: borderRadius,
            padding: 0.5,
            minWidth: "56px",
            backgroundColor: "background.dark",
            color: "text.secondary",
          }}
        >
          <Search />
        </ButtonBase>
      </Stack>
    </form>
  );
};

export default ExamSearchBar;
