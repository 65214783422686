import { Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../../hooks/useIsMobile";
import DirectionToggler from "../../../../common/DirectionToggler/DirectionToggler";
import ThemeToggler from "../../../../common/ThemeToggler/ThemeToggler";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import MobileMenuButton from "./MobileMenuButton";
import { Login } from "@mui/icons-material";

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useIsMobile();

  if (!isMobile) return null;
  // Handling menu from mobile device
  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // Handling close menu
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Handling navigate and close mobile menu
  const handleNavigateFromMobile = (url: string): void => {
    handleClose();
    navigate(url);
  };

  return (
    <Fragment>
      <MobileMenuButton toggleMenu={toggleMenu} isOpen={Boolean(anchorEl)} />
      <Menu
        id="Mobile-Menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={() => handleNavigateFromMobile("/app/dashboard")}>
          <Stack
            sx={{
              minWidth: "240px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: 2,
              paddingY: 1,
            }}
          >
            <Login />
            <Typography fontWeight={700}>{t("login")}</Typography>
          </Stack>
        </MenuItem>
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
            gap: 2,
            marginBottom:1
          }}
        >
          <ThemeToggler />
          <DirectionToggler />
        </Stack>
        <LanguageSelect onSelect={() => setAnchorEl(null)} />
      </Menu>
    </Fragment>
  );
};

export default MobileMenu;
